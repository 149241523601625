// styles
import styles from "./styles.module.scss"

const services = [
    'юридичний супровід бізнесу',
    'кримінальні справи',
    'цивільні справи',
    'господарські справи',
    'адміністративні справи',
    'справи з ДТП',
    'сімейне право',
    'медичні спори',
    'трудові спори',
    'страхові спори',
    'адміністративні правопорушення',
    'досудове врегулювання спорів',
    'захист клієнта у виконавчій службі'
]

function SnServices() {
    return (
        <section className={`${styles.services} transition y-sync`}>
            <h1 className="main-title typography-h2">
                Послуги
            </h1>
            <ul>
                {services.map((service, key) => (
                    <li key={key} className="typography-t1 white">
                        {service}
                    </li>
                ))}
            </ul>
        </section>
    )
}

export default SnServices;