import React from 'react';

//styles
import styles from './styles.module.scss';

function PrButton(props) {
  return (
    <button 
      className={`deafault-button ${styles.button} ${props.type === 'primary' ? styles.primary : styles.secondary}`}
      onClick={() => {props.scrollFn()}}
    >
      <p className='typography-t1 style-1 white'>{props.title}</p>
    </button>
  );
}

export default PrButton;
