import * as React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

// modules
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// components
// main component
import AppComponent from './components/app-component/app-component';
// global pages
import HomePage from './pages/home/home';

// styles
import './styles/main.scss';



ReactDOM.render(
  <BrowserRouter>
    <AppComponent>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </AppComponent>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
