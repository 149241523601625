import React from 'react';

// components
// import SnAboutUs from '../../components/--sections/sn-about-us/sn-about-us';
import SnServices from '../../components/--sections/sn-services/sn-services';
import SnMembers from '../../components/--sections/sn-members/sn-members';
import SnFirstView from '../../components/--sections/sn-first-view/sn-first-view';

// assets
import mainLogo from '../../assets/logo.png';

// styles
import styles from './styles.module.scss';

function HomePage() {
  React.useEffect(() => {
    setTimeout(() => {
      const ySyncListDOM = window.document.querySelectorAll('.y-sync')
      animationScrollController(ySyncListDOM)
      window.addEventListener('scroll', (event) => {
        animationScrollController(ySyncListDOM)
      });
    }, 1000);
  }, [])

  const animationScrollController =  React.useCallback((DOMList) => {
    if (DOMList) {
      const gap = 70;
      for (let index = 0; index < DOMList.length; index++) {
        const canTargetFillScene =
          window.document.documentElement.getBoundingClientRect().height -
            DOMList[index].getBoundingClientRect().height >
          0;
        const isTopTailInScene =
          DOMList[index].getBoundingClientRect().top > 0 &&
          window.innerHeight > Math.abs(DOMList[index].getBoundingClientRect().top);
        const isBottomTailInScene =
          DOMList[index].getBoundingClientRect().bottom > 0 &&
          window.innerHeight >
            Math.abs(DOMList[index].getBoundingClientRect().bottom);
        const isTopTailToExit =
          DOMList[index].getBoundingClientRect().top - window.innerHeight + gap < 0;
        const isBottomTailToExit =
          DOMList[index].getBoundingClientRect().bottom - gap < 0;
  
        if (isTopTailInScene || isBottomTailInScene)
          DOMList[index].classList.add('enter');
        else if (!DOMList[index].classList.contains('once'))
          DOMList[index].classList.remove('enter');
        if (!DOMList[index].classList.contains('once')) {
          if (isTopTailToExit) DOMList[index].classList.add('hide-top');
          else DOMList[index].classList.remove('hide-top');
          if (isBottomTailToExit) DOMList[index].classList.add('hide-bottom');
          else DOMList[index].classList.remove('hide-bottom');
        }
        if (!canTargetFillScene) DOMList[index].classList.add('tall-item');
        else DOMList[index].classList.remove('tall-item');
      }
    }
  }, [])

  const scrollToContacts = React.useCallback(() => {
    const testDOM = document.body.querySelector('.memebers-section')
    window.scrollTo({
      top: window.scrollY + testDOM.getBoundingClientRect().y - 50,
      behavior: "smooth",
    });
    console.log(testDOM.getBoundingClientRect().y, 'test 322', window.scrollY, testDOM)
  }, [])

  return (
    <div className={`${styles.page} page animation-host done`}>
      <div className='main-logo'>
        <img src={mainLogo} alt="logo" />
      </div>
      <div className='top-section'>
        <SnFirstView scrollFn={scrollToContacts}/>
      </div>
      <div className='content-wrapper'>
        <SnServices />
        {/* <SnAboutUs/> */}
        <SnMembers/>
        <section className='y-sync'>
          <iframe width="100%" height="400" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Shuster%20I%20Partnery%20%D0%B2%D1%83%D0%BB%D0%B8%D1%86%D1%8F%20%D0%93%D1%80%D1%83%D1%88%D0%B5%D0%B2%D1%81%D1%8C%D0%BA%D0%BE%D0%B3%D0%BE,%2085,%20%D0%A5%D0%BC%D0%B5%D0%BB%D1%8C%D0%BD%D0%B8%D1%86%D1%8C%D0%BA%D0%B8%D0%B9,%20%D0%A5%D0%BC%D0%B5%D0%BB%D1%8C%D0%BD%D0%B8%D1%86%D1%8C%D0%BA%D0%B0%20%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,%20%D0%A3%D0%BA%D1%80%D0%B0%D0%B8%D0%BD%D0%B0,%2029000%20Khmelnytskyi+(Shuster%20I%20Partnery)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </section>
        <footer>
          <p className='typography-t1'>
            шустер і партнери © 2023
          </p>
        </footer>
      </div>
    </div>
  );
}

export default HomePage;
