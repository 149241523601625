import { FaViber, FaWhatsapp, FaInstagram, FaFacebook } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
// assets
import member1 from '../../../assets/member1.jpg'
import member2 from '../../../assets/member2.jpg'
import member3 from '../../../assets/member3.jpg'
import member4 from '../../../assets/member4.jpg'
import member5 from '../../../assets/member5.jpg'
import member6 from '../../../assets/member6.jpg'

// styles
import styles from "./styles.module.scss"

const members = [
    {
        name: 'Шустер Ярослав',
        phone: '+380682025607',
        socials: {
            viber: 'viber://chat/?number=%2B380682025607',
            wa: 'https://wa.me/+380682025607',
        },
        image: member1
    },
    {
        name: 'Задачін Олександр',
        phone: '+380679047914',
        socials: {
            viber: 'viber://chat/?number=%2B380679047914',
            wa: 'https://wa.me/+380679047914',
        },
        image: member2
    },
    {
        name: 'Кемінь Сергій',
        phone: '+380676493852',
        socials: {
            viber: 'viber://chat/?number=%2B380676493852',
            wa: 'https://wa.me/+380676493852',
            insta: 'https://instagram.com/advocate.kemin?igshid=OGQ5ZDc2ODk2ZA==',
            fb: 'https://www.facebook.com/profile.php?id=100077708883192',
        },
        image: member3
    },
    {
        name: 'Муращик Олександр',
        phone: '+380978769376',
        socials: {
            viber: 'viber://chat/?number=%2B380978769376',
            wa: 'https://wa.me/+380978769376',
        },
        image: member4
    },
    {
        name: 'Стаднічук Володимир',
        phone: '+380679069218',
        socials: {
            viber: 'viber://chat/?number=%2B380679069218',
            wa: 'https://wa.me/+380679069218',
        },
        image: member5
    },
    {
        name: 'Лемешко Сергій',
        phone: '+380679933133',
        aditional: 'Засновник ТОВ "ЮК ЛЕГАЛ КОНСАЛТИНГ СЕРВІС", почесний партнер',
        socials: {
            viber: 'viber://chat/?number=%2B380679933133',
            wa: 'https://wa.me/+380679933133',
        },
        image: member6
    }
]

function SnMembers() {
    return (
        <section className={`${styles.members} memebers-section`}>
            <svg className="hiden-insta-parh-gradient">
                <defs>
                    <radialGradient id="instagramRadialGradient" cx="30%" cy="107%" r="100%">
                        <stop offset="0%" stop-color="#fdf497" />
                        <stop offset="5%" stop-color="#fdf497" />
                        <stop offset="45%" stop-color="#fd5949" />
                        <stop offset="60%" stop-color="#d6249f" />
                        <stop offset="90%" stop-color="#285AEB" />
                    </radialGradient>
                </defs>
            </svg>
            <ul>
                {members.map((member, key) => (
                    <li key={key} className="typography-t1 y-sync">
                        <div className="avatar">
                            <img src={member.image} alt={member.name + '_avatar'}></img>
                        </div>
                        <p className='typography-t1 style-1 name'>
                            {member.name}
                        </p>
                        <p className='typography-t2 aditional'>
                            {member.aditional}
                        </p>
                        <a className='typography-t1' href={'tel:' + member.phone}>
                            {member.phone}
                        </a>
                        <p className='socials'>
                            {
                                !!member.socials?.viber
                                && (
                                    <a href={member.socials?.viber} target="_blank">
                                        <FaViber className="viber-bg"/>
                                    </a>
                                )
                            }
                            {
                                !!member.socials?.wa
                                && (
                                    <a href={member.socials?.wa} target="_blank">
                                        <FaWhatsapp className="wa-bg"/>
                                    </a>
                                )
                            }
                            {
                                !!member.socials?.insta
                                && (
                                    <a href={member.socials?.insta} target="_blank">
                                        <FaInstagram className="insta-bg"/>
                                    </a>
                                )
                            }
                            {
                                !!member.socials?.fb && (
                                    <a href={member.socials?.fb} target="_blank">
                                        <FaFacebookSquare className="fb-bg"/>
                                    </a>
                                )
                            }
                        </p>
                    </li>
                ))}
            </ul>
        </section>
    )
}

export default SnMembers;