import React from 'react';

// styles
import styles from "./styles.module.scss"

// components
import PrButton from '../../--primitives/pr-button/pr-button';

// assets
import teamImage from '../../../assets/team.jpg';
import mainLogo from '../../../assets/logo.png';

function SnAboutUs(props) {
    return (
        <section className={`${styles.firstView}`}>
            <div className="image-wrapper">
                <img src={teamImage} alt="team-image" />
            </div>
            <div className="content transition y-sync">
                <div className='mobile-logo'>
                    <img src={mainLogo} alt="logo" />
                </div>
                <h1 className="typography-h2 white">
                    виграйте свою судову битву сьогодні
                </h1>
                <h4 className="typography-h3 white">
                    Довірте боротьбу за свої права нашим досвідченим юристам. Зв'яжіться з нами зараз.
                </h4>
                <PrButton 
                    type="primary"
                    title="зв'язатися"
                    scrollFn={props.scrollFn}
                ></PrButton>
            </div>
        </section>
    )
}

export default SnAboutUs;