
import React from 'react'

//modules
import { useLocation } from "react-router-dom";
import * as browserDetect from 'react-device-detect';

import styles from './styles.module.scss';

import { routes } from '../../utils/state';

// context
const AppContext = React.createContext({});
export const useAppContext = () => React.useContext(AppContext);

let lastScrollTop, animationScrollandResizeController, scroll;

function AppComponent({ children }) {
  const [browserName, toggleBrowser] =  React.useState(browserDetect.browserName.toLowerCase());
  const location = useLocation();

  // React.useEffect(() => {
  //   lastScrollTop = 0;
  //   const page = document.querySelector('.navigator-page');
  //   window.onbeforeunload = function () {
  //     // console.log("TEST");
  //     //page.classList.add('home');
      
  //     window.scrollTo(0, 0);
  //   }
  //   const screensDOM = document.querySelectorAll('.y-sync');
  //   setScrollandResize(screensDOM);
  //   return () => {
  //     document.removeEventListener("scroll", animationScrollandResizeController);
  //     if(scroll) scroll.removeEventListener("scroll",animationScrollandResizeController);
  //     window.removeEventListener('resize', animationScrollandResizeController);
  //   };
  // }, [location])

  const offset = React.useCallback((el) => {
    const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  },[]);

  const setScrollandResize = React.useCallback((screensDOM) => {
    scroll = document.querySelector('.scroll-page');
    const sliders = document.querySelectorAll('.slider-element');

    // const setBlur = (head) => {
    //   const spaceToTheTop = offset(head).top;
    //   const vh = window.innerHeight;
    //   if(!scroll) scroll = document.querySelector('.scroll-page');
    //   if (head.classList.contains('home-page')) {
    //     if (scroll.scrollTop > vh) 
    //       head.classList.add('blured');
    //     else 
    //       head.classList.remove('blured');
    //   } else {
    //     if (spaceToTheTop > 10) 
    //       head.classList.add('blured');
    //     else 
    //       head.classList.remove('blured');
    //   }
    // }

    // const hideHeader = (head) => {
    //   let top;
    //   if (scroll) top = scroll.scrollTop;
    //   else top = window.pageYOffset;

    //   if (lastScrollTop > top) {
    //     if (head.classList.contains('home-page')) {
    //       if (scroll.scrollHeight - top > scroll.clientHeight)
    //         if (head.classList.contains('unvisuble'))
    //           head.classList.remove('unvisuble');
    //     } else {
    //       if (head.classList.contains('unvisuble'))
    //         head.classList.remove('unvisuble');
    //     }
    //   } else if (lastScrollTop < top) {
    //     if (top >= 10)
    //       if (!head.classList.contains('unvisuble'))
    //         head.classList.add('unvisuble');
    //   }
    //   lastScrollTop = top;
    // }

    const transYsync = () => {
      for (let index = 0; index < screensDOM.length; index++) {
        const pxSize = 40;
        if (
          !screensDOM[index].classList.contains('enter')
          && screensDOM[index].getBoundingClientRect().top - window.innerHeight < pxSize
          && screensDOM[index].getBoundingClientRect().bottom > pxSize
        ) {
          screensDOM[index].classList.remove('exit');
          screensDOM[index].classList.add('enter');
        } else if (
          screensDOM[index].classList.contains('enter')
          &&
          !screensDOM[index].classList.contains('once')
          &&
          (
            (screensDOM[index].getBoundingClientRect().top - window.innerHeight > pxSize
              && screensDOM[index].getBoundingClientRect().bottom > pxSize)
            ||
            (screensDOM[index].getBoundingClientRect().top - window.innerHeight < pxSize
              && screensDOM[index].getBoundingClientRect().bottom < pxSize))
        ) {
          screensDOM[index].classList.remove('enter');
          screensDOM[index].classList.remove('exit');
        }
        if (screensDOM[index].classList.contains('enter') && screensDOM[index].getBoundingClientRect().bottom < (pxSize * 2)) {
          screensDOM[index].classList.remove('enter');
          screensDOM[index].classList.add('exit');
        }
      }
    }

    animationScrollandResizeController = () => {
      // const head = document.querySelector(".page-header");
      // setBlur(head);
      // hideHeader(head);
      transYsync();
    }
    if (scroll)
      scroll.addEventListener("scroll", animationScrollandResizeController);
    else document.addEventListener("scroll", animationScrollandResizeController);
    window.addEventListener('resize', animationScrollandResizeController);
  }, []);
 
  return (
    <AppContext.Provider
      value={{
        states: {
          browserName,
          location
        }
      }}
    >
      <div className={`${styles.appComponent} app`}>
        {children}
      </div>
    </AppContext.Provider>
  );
}

export default AppComponent;
